import React, { useState } from 'react';
import { AlertCircle, Check, Loader } from 'lucide-react';

const API_BASE_URL = 'https://methsync.web-peak.ca/api';

export default function SyncInterface() {
  const [sku, setSku] = useState('');
  const [status, setStatus] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Existing functions remain unchanged
  const handleApiError = async (response) => {
    if (!response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const error = await response.json();
        throw new Error(error.message || 'API error occurred');
      } else {
        throw new Error(`Server error: ${response.status}`);
      }
    }
    return response.json();
  };
  
  const syncSku = async () => {
    if (!sku) return;
    
    setIsLoading(true);
    setStatus(null);
    
    try {
      const response = await fetch(`${API_BASE_URL}/sync/sku`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sku: sku.trim() })
      });
      
      const result = await handleApiError(response);
      setStatus(result);
    } catch (error) {
      setStatus({ 
        success: false, 
        message: error.message || 'Failed to sync SKU' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  // New preview function
  const getPreview = async () => {
    setIsLoading(true);
    setStatus(null);
    
    try {
      const response = await fetch(`${API_BASE_URL}/sync/preview`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      });
      
      const result = await handleApiError(response);
      setPreview(result.preview);
    } catch (error) {
      setStatus({ 
        success: false, 
        message: error.message || 'Failed to get sync preview' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Modified syncAll to use preview
  const syncAll = async () => {
    setIsLoading(true);
    setStatus(null);
    setPreview(null);
    
    try {
      const response = await fetch(`${API_BASE_URL}/sync/all`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      });
      
      const result = await handleApiError(response);
      setStatus(result);
    } catch (error) {
      setStatus({ 
        success: false, 
        message: error.message || 'Failed to sync all items' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-lg w-full p-8 bg-white shadow-lg rounded-lg border border-gray-300">
        <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Method CRM Sync</h1>

        {/* Existing logo section remains unchanged */}
        <div className="flex items-center justify-center space-x-6 pb-4">
          <div className="w-24 h-24 flex items-center justify-center">
            <img src="shopify.png" alt="Shopify Logo" className="w-24 h-24 object-contain" />
          </div>

          <svg
            className="h-12 w-12 text-blue-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <polyline points="12 16 16 12 12 8" />
            <line x1="8" y1="12" x2="16" y2="12" />
          </svg>

          <div className="w-24 h-24 flex items-center justify-center">
            <img src="method.png" alt="Method CRM Logo" className="w-24 h-24 object-contain" />
          </div>
        </div>

        <div className="space-y-6">
          {/* Single SKU sync section */}
          <div className="flex flex-col sm:flex-row gap-4">
            <input
              type="text"
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              placeholder="Enter SKU"
              className="flex-1 p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={syncSku}
              disabled={isLoading || !sku}
              className={`px-4 py-3 text-white font-semibold rounded-md shadow-sm transition ${
                isLoading || !sku ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Sync SKU
            </button>
          </div>

          {/* New Preview and Sync All section */}
          <div className="space-y-4">
            <button
              onClick={getPreview}
              disabled={isLoading}
              className="w-full px-4 py-3 text-white font-semibold rounded-md bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400"
            >
              Get Sync Preview
            </button>

            {preview && (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <h3 className="font-semibold text-lg mb-3">Sync Preview</h3>
                <div className="space-y-2">
                  <p>Total SKUs in Shopify: <span className="font-semibold">{preview.total}</span></p>
                  <p>Items to Create: <span className="font-semibold text-green-600">{preview.to_create}</span></p>
                  <p>Items to Update: <span className="font-semibold text-blue-600">{preview.to_update}</span></p>
                  
                  {preview.to_create + preview.to_update > 0 && (
                    <button
                      onClick={syncAll}
                      disabled={isLoading}
                      className="mt-4 w-full px-4 py-2 text-white font-semibold rounded-md bg-green-500 hover:bg-green-600 disabled:bg-gray-400"
                    >
                      Proceed with Sync
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Loading indicator */}
        {isLoading && (
          <div className="mt-6 flex items-center justify-center gap-2 text-blue-500">
            <Loader className="animate-spin w-6 h-6" />
            <span className="text-lg font-medium">Syncing...</span>
          </div>
        )}

        {/* Status message */}
        {status && (
          <div
            className={`mt-6 p-4 rounded-lg shadow-sm ${
              status.success ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
            }`}
          >
            {status.success ? (
              <div className="flex items-center gap-2">
                <Check className="w-6 h-6" />
                <span className="font-medium">{status.message}</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <AlertCircle className="w-6 h-6" />
                <span className="font-medium">{status.message}</span>
              </div>
            )}

            {status.total && (
              <div className="mt-4 text-sm space-y-1">
                <p>Total Items: <span className="font-semibold">{status.total}</span></p>
                <p>Successful: <span className="font-semibold">{status.success}</span></p>
                <p>Errors: <span className="font-semibold text-red-600">{status.errors}</span></p>
                <p>Skipped: <span className="font-semibold">{status.skipped}</span></p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}